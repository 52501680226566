// Generated by Framer (89417f6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./tBc4YxOJM-0.js";

const cycleOrder = ["ChlWcL5NA", "q6BEouzKx"];

const variantClassNames = {ChlWcL5NA: "framer-v-9r5kft", q6BEouzKx: "framer-v-84jvjc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 0, delay: 0, mass: 7, stiffness: 50, type: "spring"}};

const valuesByLocaleId = {NTHK784vL: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "ChlWcL5NA", "Variant 2": "q6BEouzKx"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ChlWcL5NA"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ChlWcL5NA", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppeark8ulmd = activeVariantCallback(async (...args) => {
await delay(() => setVariant("q6BEouzKx"), 200)
})

const onAppear1d52i73 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("ChlWcL5NA"), 200)
})

useOnVariantChange(baseVariant, {default: onAppeark8ulmd, q6BEouzKx: onAppear1d52i73})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-0QSqK", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-9r5kft", className)} data-border data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"ChlWcL5NA"} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "rgb(255, 255, 255)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", boxShadow: "0px 0.6021873017743928px 3.010936508871964px -1.5px hsla(64, 100%, 30%, 0.85183), 0px 2.288533303243457px 11.442666516217285px -3px hsla(64, 100%, 30%, 0.71692), 0px 10px 50px -4.5px hsla(64, 100%, 30%, 0.1)", ...style}} variants={{q6BEouzKx: {boxShadow: "0px 0.6021873017743928px 3.010936508871964px 0px hsla(64, 100%, 30%, 0.06022), 0px 2.288533303243457px 11.442666516217285px 0px hsla(64, 100%, 30%, 0.22885), 0px 10px 50px 0px hsla(64, 100%, 30%, 1)"}}} {...addPropertyOverrides({q6BEouzKx: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}/></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-0QSqK [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-0QSqK .framer-13gg7gl { display: block; }", ".framer-0QSqK .framer-9r5kft { height: 403px; overflow: hidden; position: relative; width: 498px; }", ".framer-0QSqK.framer-v-84jvjc .framer-9r5kft { aspect-ratio: 1.234592445328032 / 1; height: var(--framer-aspect-ratio-supported, 403px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 403
 * @framerIntrinsicWidth 498
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"q6BEouzKx":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables false
 */
const FramertBc4YxOJM: React.ComponentType<Props> = withCSS(Component, css, "framer-0QSqK") as typeof Component;
export default FramertBc4YxOJM;

FramertBc4YxOJM.displayName = "obrys c";

FramertBc4YxOJM.defaultProps = {height: 403, width: 498};

addPropertyControls(FramertBc4YxOJM, {variant: {options: ["ChlWcL5NA", "q6BEouzKx"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramertBc4YxOJM, [])